import { useIntl } from 'react-intl';

import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import RadioButton from '@luna-protocol/core/src/components/RadioButton/RadioButton';
import { useState } from 'react';
import messages from './POISelect.messages';

import { NavLink } from 'react-router-dom';
import './POISelect.scss';

const POISelect = () => {
  const { formatMessage } = useIntl();
  const [selected, setSelected] = useState('');
  return (
    <>
      <Banner>{formatMessage(messages.title)} </Banner>
      <Body>
        <p>{formatMessage(messages.description)}</p>
        <ul>
          <li className={'select-row'}>
            <RadioButton
              checked={selected === 'bank'}
              onChange={() => {
                if (selected === 'bank') {
                  setSelected('');
                  return;
                }
                setSelected('bank');
              }}
            />
            <span>{formatMessage(messages.bank)}</span>
          </li>
          <li className={'select-row'}>
            <RadioButton
              checked={selected === 'document'}
              onChange={() => {
                if (selected === 'document') {
                  setSelected('');
                  return;
                }
                setSelected('document');
              }}
            />
            <span>{formatMessage(messages.document)}</span>
          </li>
        </ul>
        <ButtonGroup>
          <NavLink to={`/proof_of_income/${selected}`}>
            <Button width={217} disabled={selected === ''}>
              {formatMessage(messages.continue)}
            </Button>
          </NavLink>
        </ButtonGroup>
      </Body>
    </>
  );
};

export default POISelect;
