import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'approval.title',
    defaultMessage: 'Congratulations!',
  },
  amountTitle: {
    id: 'approval.amountTitle',
    defaultMessage: 'Prequalified finance amount',
  },
  amountDetails: {
    id: 'approval.amountDetails',
    defaultMessage:
      'You are prequalified for up to {amount}! This credit amount can be used to cover the base product(s) and permitted add-ons',
  },
  dealerDeviceamountDetails: {
    id: 'approval.dealerDeviceamountDetails',
    defaultMessage:
      'Your customer is prequalified for up to {amount}! This credit amount can be used to cover the base product(s) and permitted add-ons',
  },
  dealerTitle: {
    id: 'approval.dealerTitle',
    defaultMessage: 'Choose your Dealer Location',
  },
  dealerDetails: {
    id: 'approval.dealerDetails',
    defaultMessage: "Select which local dealership you'd like to visit to discuss your order:",
  },
  zipcodeTitle: {
    id: 'approval.zipcodeTitle',
    defaultMessage: 'Your zip code',
  },
  searchMore: {
    id: 'approval.searchMore',
    defaultMessage: 'Search for more locations',
  },
  onlyIDVRequired: {
    id: 'approval.onlyIDVRequired',
    defaultMessage:
      'Continue to provide identity verification where necessary in order to speed up in-store deal completion',
  },
  continue: {
    id: 'approval.continue',
    defaultMessage: 'Continue',
  },
  searchButtonLabel: {
    id: 'approval.searchButtonLabel',
    defaultMessage: 'Search zip code',
  },
  closeLabel: {
    id: 'approval.closeLabel',
    defaultMessage: 'You’re all done. You may close this window.',
  },
});

export default messages;
