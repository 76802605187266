import NotFound from '@luna-protocol/core/src/components/NotFound/NotFound';
import Screen from '@luna-protocol/core/src/components/Screen/Screen';
import Verification from '@luna-protocol/core/src/components/Verification/Verification';
import { Route } from 'react-router-dom';
import PaymentSetup from '../../web.consumer/src/components/PaymentSetup/PaymentSetup.tsx';
import Accept from './components/Accept/Accept.tsx';
import ApplicationForm from './components/ApplicationForm/ApplicationForm';
import Approval from './components/Approval/Approval';
import CoAppComplete from './components/CoAppComplete/CoAppComplete.tsx';
import ConfirmDetails from './components/ConfirmDetails/ConfirmDetails.tsx';
import CustomerReview from './components/CustomerReview/CustomerReview.tsx';
import DealerNotified from './components/DealerNotified/DealerNotified.tsx';
import Denied from './components/Denied/Denied.tsx';
import Flags from './components/Flags/Flags.tsx';
import Frozen from './components/Frozen/Frozen.tsx';
import IDVComplete from './components/IDVComplete/IDVComplete.tsx';
import IdentityVerification from './components/IdentityVerification/IdentityVerification.tsx';
import InstructionPage from './components/InstructionPage/InstructionPage.tsx';
import POIBank from './components/POIBank/POIBank.tsx';
import POIComplete from './components/POIComplete/POIComplete.tsx';
import POIDocument from './components/POIDocument/POIDocument.tsx';
import POISelect from './components/POISelect/POISelect.tsx';
import Processing from './components/Processing/Processing';
import SignDocs from './components/SignDocs/SignDocs';
import Signed from './components/Signed/Signed.tsx';
import Stipulation from './components/Stipulation/Stipulation.tsx';
import UnderReview from './components/UnderReview/UnderReview';
import WaitingForCoapplicant from './components/WaitingForCoapplicant/WaitingForCoapplicant';
import { usePostVerification } from './queries/usePostVerification.ts';
import { useSendCode, useSendCodeWithDisclosures, useSendCodeWithFinal } from './queries/useSendCode.ts';
import PricingError from './components/PricingError/PricingError.tsx';

const routes = (
  <Route element={<Screen />}>
    {/*  Prequal Links */}
    <Route path="/application/*" element={<ApplicationForm />} />
    <Route path="/coapp_complete" element={<CoAppComplete />} />
    <Route
      path="/verify"
      element={<Verification resendCodeCustomer={useSendCode} submitCodeCustomer={usePostVerification} />}
    />
    <Route
      path="/verify_disclosures"
      element={
        <Verification resendCodeCustomer={useSendCodeWithDisclosures} submitCodeCustomer={usePostVerification} />
      }
    />
    <Route
      path="/verify_final"
      element={<Verification resendCodeCustomer={useSendCodeWithFinal} submitCodeCustomer={usePostVerification} />}
    />
    <Route path="/processing" element={<Processing />} />
    <Route path="/pricing_error" element={<PricingError />} />
    <Route path="/notify_dealer" element={<DealerNotified />} />
    <Route path="/approval" element={<Approval />} />
    <Route path="/stipulation_review" element={<Stipulation />} />
    <Route path="/under_review" element={<UnderReview />} />
    <Route path="/waiting_for_coapplicant" element={<WaitingForCoapplicant />} />
    <Route path="/identity_verification" element={<IdentityVerification />} />
    <Route path="/idv_complete" element={<IDVComplete />} />
    <Route path="/confirm_details" element={<ConfirmDetails />} />
    <Route path="/frozen" element={<Frozen />} />

    {/*  This page is linked from Emails to help customers resolve any outstanding flags before they  */}
    <Route path="/flags" element={<Flags />} />
    <Route path="/proof_of_income" element={<POISelect />} />
    <Route path="/proof_of_income/bank" element={<POIBank />} />
    <Route path="/proof_of_income/document" element={<POIDocument />} />
    <Route path="/proof_of_income/complete" element={<POIComplete />} />

    {/*  Review&Sign Links */}
    <Route path="/review" element={<CustomerReview />} />
    <Route path="/payment_setup" element={<PaymentSetup />} />
    <Route path="/sign_docs" element={<SignDocs />} />
    <Route path="/signed" element={<Signed />} />
    <Route path="/denied" element={<Denied />} />
    <Route path="/accept_disclosures" element={<Accept />} />
    <Route path="/email_sent" element={<InstructionPage />} />

    <Route path="*" element={<NotFound />} />
  </Route>
);

export default routes;
