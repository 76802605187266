import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'under_review.title',
    defaultMessage: 'There are no credit offers available for the customer(s).',
  },
  detail1: {
    id: 'under_review.detail1',
    defaultMessage: 'Your customer(s) will be emailed additional information shortly.',
  },
});

export default messages;
