import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'signDocs.title',
    defaultMessage: "You're almost done.",
  },
  description: {
    id: 'signDocs.description',
    defaultMessage: `Click the button below to review the contract documents and provide your digital signature. A copy of the contract documents will be emailed to you.`,
  },
  disclaimer: {
    id: 'signDocs.disclaimer',
    defaultMessage: `If there is a co-applicant, both you and the co-applicant must sign the contract agreement on the same day.`,
  },
  button: {
    id: 'signDocs.button',
    defaultMessage: 'Review contract documents',
  },
});

export default messages;
