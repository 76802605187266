import axios from 'axios';
import { useMutation } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export interface ContractUrl {
  docusign_link: string;
}

const getContractLink = async (loan_application_id: string) => {
  const resp = await axios.get(`${getBaseApi()}/consumer/application/${loan_application_id}/contract`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('user_token')}`,
    },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get docusign link');
  }
  return resp.data as ContractUrl;
};

const useGetContract = () => {
  const mutation = useMutation((loan_application_id: string) => {
    return getContractLink(loan_application_id);
  }, {});

  return {
    getContract: mutation.mutate,
    ...mutation,
  };
};
export { useGetContract };
