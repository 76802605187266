import { useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { useNavigate, useSearchParams } from 'react-router-dom';

import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton.tsx';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import messages from './IdentityVerification.messages';

import Body from '@luna-protocol/core/src/components/Body/Body.tsx';
import { AppContext } from '../../AppContext.tsx';
import { useGetLinkToken } from '../../queries/useGetIDVLinkToken.ts';
import { useCustomerAuth } from '../../utils/useCustomerAuth.ts';

const IdentityVerification = () => {
  useCustomerAuth();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const { loanApplicationID, setLoanApplicationID } = useContext(AppContext);
  const { data } = useGetLinkToken(loanApplicationID);

  const config: PlaidLinkOptions = {
    onSuccess: async () => {
      navigate('/idv_complete');
    },
    onExit: async () => {
      navigate('/stipulation_review');
    },
    token: data?.link_token || '',
  };

  const { open, ready, error } = usePlaidLink(config);
  const count = useRef(0);

  useEffect(() => {
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      let parameter = param;

      if (param.indexOf('?')) {
        parameter = param.slice(param.indexOf('?') + 1, param.length);
      }

      if (parameter === 'loan_application_id') {
        setLoanApplicationID(value);
      }
    }
    if (error) {
      console.error(error);
    }

    if (ready && data?.link_token !== '' && count.current === 0) {
      open();
      count.current += 1;
    }
  }, [data, error, ready, open, searchParams]);

  return (
    <>
      <BackButton path="/stipulation_review" inverted />
      <Banner>{formatMessage(messages.title)} </Banner>
      <Body>
        <p>{formatMessage(messages.description)}</p>
      </Body>
    </>
  );
};

export default IdentityVerification;
