import './RadioButton.scss';

interface RadioButtonProps {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

const RadioButton = ({ checked, onChange, disabled = false }: RadioButtonProps) => {
  return (
    <input
      type="radio"
      checked={checked}
      aria-label="selected"
      className={`radio-button ${checked ? 'selected' : ''}`}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default RadioButton;
