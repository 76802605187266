import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'dealerNotified.title',
    defaultMessage: 'Your dealer has been notified that you would like to progress the deal',
  },
});

export default messages;
