import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'idv_complete.title',
    defaultMessage: 'Thank you for submitting your identity verification documents.',
  },
  detail1: {
    id: 'idv_complete.detail1',
    defaultMessage: 'You will be notified once a decision is made.',
  },
});

export default messages;
