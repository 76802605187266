import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'customerReview.title',
    defaultMessage: 'View your total deal summary',
  },
  subtitle: {
    id: 'customerReview.subtitle',
    defaultMessage: 'Summary',
  },
  customerTitle: {
    id: 'customerReview.customerTitle',
    defaultMessage: 'Customer details',
  },
  balanceTitle: {
    id: 'customerReview.balanceTitle',
    defaultMessage: 'Total Balance Due',
  },
  orderTitle: {
    id: 'customerReview.orderTitle',
    defaultMessage: 'Order details',
  },
  financeTitle: {
    id: 'customerReview.financeTitle',
    defaultMessage: 'Financing details',
  },
  feesTaxTitle: {
    id: 'customerReview.feesTaxTitle',
    defaultMessage: 'Fees and taxes',
  },
  backendProductsTitle: {
    id: 'customerReview.backendProductsTitle',
    defaultMessage: 'Backend products',
  },
  socialSecurityNumber: {
    id: 'customerReview.socialSecurityNumber',
    defaultMessage: 'Primary Applicant SSN',
  },
  socialSecurityNumberPlaceholder: {
    id: 'customerReview.socialSecurityNumberPlaceholder',
    defaultMessage: 'Please enter',
  },
  disclaimer: {
    id: 'customerReview.disclaimer',
    defaultMessage: 'To proceed with your financing, please enter your SSN and confirm the details above are correct.',
  },
  attestationCheckbox: {
    id: 'customerReview.attestationCheckbox',
    defaultMessage: 'By ticking this box you agree that all information above is correct.',
  },
  basePurchasePrice: {
    id: 'customerReview.basePurchasePrice',
    defaultMessage: 'Base purchase price',
  },
  feesAndTaxes: {
    id: 'customerReview.feesAndTaxes',
    defaultMessage: 'Fees and taxes',
  },
  backendProducts: {
    id: 'customerReview.backendProducts',
    defaultMessage: 'Backend products',
  },
  accessories: {
    id: 'customerReview.accessories',
    defaultMessage: 'Accessories',
  },
  downPayment: {
    id: 'customerReview.downPayment',
    defaultMessage: 'Down payment',
  },
  totalAmountFinanced: {
    id: 'customerReview.totalAmountFinanced',
    defaultMessage: 'Total amount financed',
  },
  remainingBalance: {
    id: 'customerReview.remainingBalance',
    defaultMessage: 'Remaining balance',
  },
  customerName: {
    id: 'customerReview.customerName',
    defaultMessage: 'Customer name',
  },
  customerEmail: {
    id: 'customerReview.customerEmail',
    defaultMessage: 'Email address',
  },
  customerPhone: {
    id: 'customerReview.customerPhone',
    defaultMessage: 'Phone number',
  },
  coapplicantName: {
    id: 'customerReview.coapplicantName',
    defaultMessage: 'Co-applicant name',
  },
  colorLabel: {
    id: 'customerReview.colorLabel',
    defaultMessage: 'Color',
  },
  modelLabel: {
    id: 'customerReview.modelLabel',
    defaultMessage: 'Model',
  },
  hinLabel: {
    id: 'customerReview.hinLabel',
    defaultMessage: 'HIN',
  },
  vinLabel: {
    id: 'customerReview.vinLabel',
    defaultMessage: 'VIN',
  },
  trailerLabel: {
    id: 'customerReview.trailerLabel',
    defaultMessage: 'Trailer serial number',
  },
  engineLabel: {
    id: 'customerReview.engineLabel',
    defaultMessage: 'Engine serial number',
  },
  serialLabel: {
    id: 'customerReview.serialLabel',
    defaultMessage: 'Serial number',
  },
  itemLabel: {
    id: 'customerReview.itemLabel',
    defaultMessage: 'Item',
  },
  total: {
    id: 'customerReview.total',
    defaultMessage: 'Total',
  },
  monthlyRepayment: {
    id: 'customerReview.monthlyRepayment',
    defaultMessage: 'Monthly Payment',
  },
  repaymentPeriod: {
    id: 'customerReview.repaymentPeriod',
    defaultMessage: 'Repayment period',
  },
  apr: {
    id: 'customerReview.apr',
    defaultMessage: 'APR',
  },
  dateOfFirstPayment: {
    id: 'customerReview.dateOfFirstPayment',
    defaultMessage: 'Date of first payment',
  },
  dateOfLastPayment: {
    id: 'customerReview.dateOfLastPayment',
    defaultMessage: 'Date of last payment',
  },
  feesLabel: {
    id: 'customerReview.feesLabel',
    defaultMessage: 'Fees',
  },
  taxesLabel: {
    id: 'customerReview.taxesLabel',
    defaultMessage: 'Taxes',
  },
  completeButton: {
    id: 'customerReview.completeButton',
    defaultMessage: 'Complete purchase now',
  },
  checkboxDisclaimer: {
    id: 'customerReview.checkboxDisclaimer',
    defaultMessage: 'By submitting, a “hard” inquiry will be made and this may negatively impact your credit score.',
  },
});

export default messages;
