import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton.tsx';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body.tsx';
import Button from '@luna-protocol/core/src/components/Button/Button.tsx';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup.tsx';
import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak.tsx';
import Modal from '@luna-protocol/core/src/components/Modal/Modal.tsx';

import { useSessionStorage } from 'usehooks-ts';
import messages from './Stipulation.messages.ts';
import { useCustomerAuth } from '../../utils/useCustomerAuth.ts';

const Stipulation = () => {
  useCustomerAuth();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // useSessionStorage is used here, in case the user navigates away from the page and comes back
  const [stipulationRead, setStipulationRead] = useSessionStorage('has_real_idv_stip', false);

  const handleButtonClick = () => {
    if (!stipulationRead) {
      setModalIsOpen(true);
    } else {
      navigate('/identity_verification');
    }
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setStipulationRead(true);
  };

  return (
    <>
      <BackButton path="/approval" inverted />
      <Banner>{formatMessage(messages.title)}</Banner>
      <Body>
        <p>{formatMessage(messages.body)}</p>
        <ButtonGroup align="left">
          <Button variant="secondary" name="continue" onClick={handleButtonClick}>
            {formatMessage(messages.button)}
          </Button>
        </ButtonGroup>
      </Body>
      {modalIsOpen && (
        <Modal title={formatMessage(messages.modalTitle)} onClose={handleCloseModal} centeredBody>
          <p>{formatMessage(messages.modalBody)}</p>
          <LineBreak largeMargin />
          <p>{formatMessage(messages.modalQuestion)}</p>
          <Link
            className="modal-link"
            to="https://plaid.com/legal/#end-user-privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="End user privacy policy"
            title="End user privacy policy">
            {formatMessage(messages.privacyPolicy)}
          </Link>
          <LineBreak />
          <Link
            className="modal-link"
            to="https://support-my.plaid.com/hc/en-us"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Consumer help center"
            title="Consumer help center">
            {formatMessage(messages.helpCenter)}
          </Link>
        </Modal>
      )}
    </>
  );
};

export default Stipulation;
