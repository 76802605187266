import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';

import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import Input from '@luna-protocol/core/src/components/Input/Input';
import SelectInput from '@luna-protocol/core/src/components/SelectInput/SelectInput';
import { stateCodeMapping } from '@luna-protocol/core/src/utils/constants/states.js';

import useToast from '@luna-protocol/core/src/utils/useToast.ts';
import { useSessionStorage } from 'usehooks-ts';
import { AppContext } from '../../AppContext.tsx';
import { useGetCustomer } from '../../queries/useGetCustomer.ts';
import { useGetFlags } from '../../queries/useGetFlags.ts';
import { usePostCustomerDetails } from '../../queries/usePostCustomerDetails.ts';
import { ConfirmDetailsFormValues, UpdateCustomerDetails } from '../../types.ts';
import messages from './ConfirmDetails.messages';
import { confirmationSchema } from './confirmationSchema.ts';

const ConfirmDetails = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { loanApplicationID, customerID, userDetails } = useContext(AppContext);
  const { data: flags } = useGetFlags(loanApplicationID);
  const { submitDetails } = usePostCustomerDetails();
  const { data: customerData } = useGetCustomer(customerID);
  const { createToast } = useToast();
  const [triesLeft, setTriesLeft] = useSessionStorage('retries', 3);
  const [pending, setPending] = useState(false);

  const initialValues: ConfirmDetailsFormValues = {
    firstName: customerData?.first_name || '',
    isFirstName: !!flags?.flags.some(flag => flag.type === 'first_name_mismatch' || flag.type === 'no_subject_found'),
    lastName: customerData?.last_name || '',
    isLastName: !!flags?.flags.some(flag => flag.type === 'last_name_mismatch' || flag.type === 'no_subject_found'),
    streetAddress: customerData?.address?.address_lines[0] || '',
    isStreetAddress: !!flags?.flags.some(
      flag => flag.type === 'current_address_mismatch' || flag.type === 'no_subject_found',
    ),
    city: customerData?.address?.city || '',
    isCity: !!flags?.flags.some(flag => flag.type === 'current_address_mismatch' || flag.type === 'no_subject_found'),
    state: stateCodeMapping.find(state => state.value === customerData?.address?.state),
    isState: !!flags?.flags.some(flag => flag.type === 'current_address_mismatch' || flag.type === 'no_subject_found'),
    zipCode: customerData?.address?.zip_code || '',
    isZipCode: !!flags?.flags.some(
      flag => flag.type === 'current_address_mismatch' || flag.type === 'no_subject_found',
    ),
    social_security_number: userDetails?.social_security_number || '', // SSN is not passed in the customer data ( so use the application form if it exists)
    isSocial_security_number: true, // SSN is always shown on this page
    isDateOfBirth: !!flags?.flags.some(flag => flag.type === 'dob_mismatch' || flag.type === 'no_subject_found'),
    dateOfBirth: customerData?.date_of_birth || '',
  };

  const onSubmit = (values: ConfirmDetailsFormValues) => {
    setPending(true);
    if (customerID !== undefined) {
      const data: UpdateCustomerDetails = {
        first_name: values.firstName,
        last_name: values.lastName,
        social_security_number: values.social_security_number,
        date_of_birth: values.dateOfBirth,
      };
      if (values.streetAddress !== '') {
        data.address = {
          address_lines: [values.streetAddress],
          city: values.city,
          state: values.state?.value,
          zip_code: values.zipCode,
        };
      }

      submitDetails([customerID ?? '', data], {
        onSuccess: () => {
          createToast({
            title: formatMessage(messages.submitSuccess),
            status: 'success',
          });
          navigate('/processing');
        },
        onError: () => {
          setPending(false);
          setTriesLeft(triesLeft - 1);

          createToast({
            title: formatMessage(messages.retryError),
            status: 'error',
            description: formatMessage(messages.remainingTries, { remainingTries: triesLeft }),
          });

          if (triesLeft === 0) {
            navigate('/denied');
          }
        },
      });
    }
  };

  return (
    <>
      <Body fullWidth>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={confirmationSchema}
          enableReinitialize>
          {({ values, errors, handleBlur, touched, handleSubmit, handleChange }) => (
            <Form>
              <div className="form-header">
                <h2>{formatMessage(messages.title)}</h2>
              </div>
              <p>{formatMessage(messages.detail1)}</p>
              <fieldset className="fieldset">
                {flags?.flags.some(flag => flag.type === 'first_name_mismatch' || flag.type === 'no_subject_found') && (
                  <Input
                    label={formatMessage(messages.firstName)}
                    placeholder={formatMessage(messages.firstName)}
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName}
                    error={!!touched.firstName && errors.firstName ? errors.firstName : undefined}
                    onBlur={handleBlur}
                  />
                )}
                {flags?.flags.some(flag => flag.type === 'last_name_mismatch' || flag.type === 'no_subject_found') && (
                  <Input
                    label={formatMessage(messages.lastName)}
                    placeholder={formatMessage(messages.lastName)}
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                    error={errors.lastName && !!touched.lastName ? errors.lastName : undefined}
                  />
                )}
                {flags?.flags.some(
                  flag => flag.type === 'current_address_mismatch' || flag.type === 'no_subject_found',
                ) && (
                  <Input
                    label={formatMessage(messages.streetAddress)}
                    placeholder={formatMessage(messages.streetAddress)}
                    name="streetAddress"
                    onChange={handleChange}
                    value={values.streetAddress}
                    error={errors.streetAddress && !!touched.streetAddress ? errors.streetAddress : undefined}
                  />
                )}
                {flags?.flags.some(
                  flag => flag.type === 'current_address_mismatch' || flag.type === 'no_subject_found',
                ) && (
                  <Input
                    label={formatMessage(messages.city)}
                    placeholder={formatMessage(messages.city)}
                    name="city"
                    onChange={handleChange}
                    value={values.city}
                    error={errors.city && !!touched.city ? errors.city : undefined}
                  />
                )}
                {flags?.flags.some(
                  flag => flag.type === 'current_address_mismatch' || flag.type === 'no_subject_found',
                ) && (
                  <SelectInput
                    label={formatMessage(messages.state)}
                    placeholder={formatMessage(messages.state)}
                    onChange={handleChange}
                    value={values.state}
                    error={errors.state && !!touched.state ? errors.state : undefined}
                    name="state"
                    options={stateCodeMapping}
                  />
                )}
                {flags?.flags.some(
                  flag => flag.type === 'current_address_mismatch' || flag.type === 'no_subject_found',
                ) && (
                  <Input
                    label={formatMessage(messages.zipCode)}
                    placeholder={formatMessage(messages.zipCode)}
                    name="zipCode"
                    onChange={handleChange}
                    value={values.zipCode}
                    error={errors.zipCode && !!touched.zipCode ? errors.zipCode : undefined}
                  />
                )}
                {flags?.flags.some(flag => flag.type === 'dob_mismatch' || flag.type === 'no_subject_found') && (
                  <Input
                    type="date"
                    label={formatMessage(messages.dateOfBirth)}
                    placeholder={formatMessage(messages.dateOfBirth)}
                    name="dateOfBirth"
                    onChange={handleChange}
                    value={values.dateOfBirth}
                    error={errors.dateOfBirth && !!touched.dateOfBirth ? errors.dateOfBirth : undefined}
                  />
                )}
                <Input
                  label={formatMessage(messages.ssn)}
                  placeholder={formatMessage(messages.ssn)}
                  name="social_security_number"
                  onChange={handleChange}
                  value={values.social_security_number}
                  error={
                    errors.social_security_number && !!touched.social_security_number
                      ? errors.social_security_number
                      : undefined
                  }
                />
              </fieldset>
              <ButtonGroup narrowWidth>
                <Button>
                  <NavLink to={'/prequalification'}>{formatMessage(messages.returnButton)}</NavLink>
                </Button>
                <Button
                  type="submit"
                  variant="secondary"
                  onClick={handleSubmit}
                  testId="detail-submit-button"
                  pending={pending}>
                  {formatMessage(messages.submit)}
                </Button>
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      </Body>
    </>
  );
};

export default ConfirmDetails;
