import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'accept.title',
    defaultMessage: "To accept the disclosures that have been emailed to you please click the 'accept' button below",
  },
  continueButton: {
    id: 'accept.continueButton',
    defaultMessage: 'I accept',
  },
});

export default messages;
