import Complete from '@luna-protocol/core/src/components/Complete/Complete';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import messages from './Denied.messages';

const Denied = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [applicationUrl] = useSessionStorage('application_url', '');

  return (
    <Complete
      title={formatMessage(messages.title)}
      body={[formatMessage(messages.body)]}
      buttonOnClick={() => (applicationUrl ? window.location.replace(applicationUrl) : navigate('/application'))}
      buttonLabel={formatMessage(messages.submit)}
    />
  );
};

export default Denied;
