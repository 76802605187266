import Complete from '@luna-protocol/core/src/components/Complete/Complete';
import coreMessages from '@luna-protocol/core/src/utils/Core.messages.ts';
import useToast from '@luna-protocol/core/src/utils/useToast.ts';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from '../../AppContext.tsx';
import { usePostAccept } from '../../queries/usePostAccept.ts';
import { useCustomerAuth } from '../../utils/useCustomerAuth.ts';
import messages from './DealerNotified.messages.ts';
import { useGetDealerBranding } from '@luna-protocol/core/src/queries/useGetDealerBranding.ts';

const DealerNotified = () => {
  useCustomerAuth();
  const { formatMessage } = useIntl();
  const { postAccept } = usePostAccept();
  const [searchParams] = useSearchParams();
  const { loanApplicationID, setLoanApplicationID } = useContext(AppContext);
  const { createToast } = useToast();

  const { setOEMType } = useContext(AppContext);
  const { isLoading, data } = useGetDealerBranding(searchParams.get('dealer_id') || '');

  useEffect(() => {
    if (data && data.oem_type) {
      setOEMType(data.oem_type);
    }
  }, [data, setOEMType]);

  useEffect(() => {
    const loanApplicationID = searchParams.get('loan_application_id');
    if (loanApplicationID) {
      setLoanApplicationID(loanApplicationID);
    }

    postAccept(loanApplicationID as string, {
      onSuccess: () => {
        createToast({
          title: `${formatMessage(coreMessages.success)}`,
          status: 'success',
        });
      },
    });
  }, [loanApplicationID]);

  if (isLoading) {
    return null;
  }

  return <Complete title={formatMessage(messages.title)} body={[]} />;
};

export default DealerNotified;
