import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'signed.title',
    defaultMessage: 'Congratulations!',
  },
  detail1: {
    id: 'signed.detail1',
    defaultMessage:
      'Thank you for signing your contract agreement! Your dealer will be in touch shortly to confirm the details and guide you through the next steps.',
  },
  detail2: {
    id: 'signed.detail2',
    defaultMessage:
      'If there is a co-applicant, both you and the co-applicant must sign the contract agreement on the same day.',
  },
});

export default messages;
