import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'paymentSetup.title',
    defaultMessage: 'Set up automatic ACH payments',
  },
  subtitle: {
    id: 'paymentSetup.subtitle',
    defaultMessage: 'By selecting AutoPay, the interest rate on your contract will be reduced by <bold>0.25%</bold>.',
  },
  descriptionOne: {
    id: 'paymentSetup.descriptionOne',
    defaultMessage:
      "Missing payments can affect your credit score and result in additional charges. If you select AutoPay, we will attempt to automatically debit payments from your bank account according to your financing account payment schedule, helping ensure that you don't miss a payment. If you do not select AutoPay, you must manually make payments.",
  },
  descriptionTwo: {
    id: 'paymentSetup.descriptionTwo',
    defaultMessage:
      'If you authorize AutoPay, you must read and sign the AutoPay Recurring Electronic Payment Authorization at contract signing.',
  },
  readMore: {
    id: 'paymentSetup.readMore',
    defaultMessage: 'You can also read the AutoPay Recurring Electronic Payment {url}.',
  },
  routingLabel: {
    id: 'paymentSetup.routingLabel',
    defaultMessage: 'Routing Number',
  },
  accountNumberLabel: {
    id: 'paymentSetup.accountNumberLabel',
    defaultMessage: 'Account Number',
  },
  fieldPlaceholder: {
    id: 'paymentSetup.fieldPlaceholder',
    defaultMessage: 'Please enter',
  },
  routingNumberError: {
    id: 'paymentSetup.routingNumberError',
    defaultMessage: 'Routing Number is required',
  },
  accountNumberError: {
    id: 'paymentSetup.accountNumberError',
    defaultMessage: 'Account Number is required',
  },
  autoPaymentsLabel: {
    id: 'paymentSetup.autoPaymentsLabel',
    defaultMessage: 'Set up automatic ACH payments',
  },
  modalTitle: {
    id: 'paymentSetup.modalTitle',
    defaultMessage: 'AutoPay Recurring Electronic Payment Authorization',
  },
  continueButton: {
    id: 'paymentSetup.continueButton',
    defaultMessage: 'Continue',
  },
  skipButton: {
    id: 'paymentSetup.skipButton',
    defaultMessage: 'Skip',
  },
});

export default messages;
