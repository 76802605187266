import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Complete from '@luna-protocol/core/src/components/Complete/Complete';
import { useGetDealerBranding } from '@luna-protocol/core/src/queries/useGetDealerBranding.ts';

import messages from './Accept.messages';
import { useCustomerAuth } from '../../utils/useCustomerAuth.ts';
import { AppContext } from '../../AppContext.tsx';
import { useContext } from 'react';
import { usePostAcceptDisclosures } from '../../queries/usePostAcceptDisclosures.ts';

const Accept = () => {
  useCustomerAuth();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { postAcceptDisclosures } = usePostAcceptDisclosures();
  const { loanApplicationID, setOEMType } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const { data: dealerBrandingData, isLoading: isDealerBrandingLoading } = useGetDealerBranding(
    searchParams.get('dealer_id') || '',
  );

  useEffect(() => {
    if (dealerBrandingData && dealerBrandingData.oem_type) {
      setOEMType(dealerBrandingData.oem_type);
    }
  }, [dealerBrandingData, setOEMType]);

  if (isDealerBrandingLoading) {
    return null;
  }

  return (
    <Complete
      title={formatMessage(messages.title)}
      body={[]}
      buttonOnClick={() => {
        postAcceptDisclosures(loanApplicationID as string, {
          onSuccess: () => {
            navigate('/processing');
          },
        });
      }}
      buttonLabel={formatMessage(messages.continueButton)}
    />
  );
};

export default Accept;
