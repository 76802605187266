import axios from 'axios';

import { useMutation } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { SEND_CODE } from './constants';
import { SendCodeConsumerRequest } from '@luna-protocol/core/src/types';

const useSendCodeWithDisclosures = () => {
  const mutation = useMutation({
    mutationKey: [SEND_CODE, 'disclosures'],
    mutationFn: (data: SendCodeConsumerRequest) => {
      return axios.post(
        `${getBaseApi()}/auth/otp/send_loan_application`,
        JSON.stringify({
          ...data,
          stage: 'Disclosure',
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
    },
  });

  return {
    sendCode: mutation.mutate,
    ...mutation,
  };
};

const useSendCodeWithFinal = () => {
  const mutation = useMutation({
    mutationKey: [SEND_CODE, 'final'],
    mutationFn: (data: SendCodeConsumerRequest) => {
      return axios.post(
        `${getBaseApi()}/auth/otp/send_loan_application`,
        JSON.stringify({
          ...data,
          stage: 'Final',
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
    },
  });

  return {
    sendCode: mutation.mutate,
    ...mutation,
  };
};

const useSendCode = () => {
  const mutation = useMutation({
    mutationKey: [SEND_CODE],
    mutationFn: (data: SendCodeConsumerRequest) => {
      return axios.post(`${getBaseApi()}/auth/otp/send_loan_application`, JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' },
      });
    },
  });

  return {
    sendCode: mutation.mutate,
    ...mutation,
  };
};

export { useSendCode, useSendCodeWithDisclosures, useSendCodeWithFinal };
