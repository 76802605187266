import axios from 'axios';
import { useQuery } from 'react-query';
import { FLAGS } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export interface FlagsResponse {
  flags: Flag[];
}

export interface Flag {
  type: string;
  status: string;
  resolver: string;
}

const getFlags = async (loanApplicationID: string): Promise<FlagsResponse> => {
  const resp = await axios.get(`${getBaseApi()}/pre_qualification/${loanApplicationID}/flags`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get flags');
  }
  return {
    flags: (resp.data as FlagsResponse).flags.filter(f => f.status !== 'resolved'),
  };
};
const useGetFlags = (loanApplicationID: string | undefined) => {
  return useQuery<FlagsResponse, Error, FlagsResponse>([FLAGS, loanApplicationID], async () => {
    if (loanApplicationID === undefined) {
      throw new Error('Loan application ID is undefined');
    }
    return await getFlags(loanApplicationID);
  });
};

export { useGetFlags };
