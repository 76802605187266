import axios from 'axios';

import { useMutation } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { START_PRICING } from './constants';

const useStartPricing = () => {
  const mutation = useMutation({
    mutationKey: [START_PRICING],
    mutationFn: (loanApplicationID: string) => {
      return axios.post(`${getBaseApi()}/pre_qualification/pricing_status/${loanApplicationID}`, null, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
      });
    },
  });

  return {
    startPricing: mutation.mutate,
    ...mutation,
  };
};

export { useStartPricing };
