import { useIntl } from 'react-intl';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';

import Banner from '@luna-protocol/core/src/components/Banner/Banner.tsx';
import Body from '@luna-protocol/core/src/components/Body/Body.tsx';
import Button from '@luna-protocol/core/src/components/Button/Button.tsx';
import Card from '@luna-protocol/core/src/components/Card/Card.tsx';
import Checkbox from '@luna-protocol/core/src/components/Checkbox/Checkbox.tsx';
import { FormatPhoneNumber, FormatTitleCase, USDollar } from '@luna-protocol/core/src/utils/constants/format.ts';
import Input from '@luna-protocol/core/src/components/Input/Input.tsx';
import { KVPair } from '@luna-protocol/core/src/types.ts';

import { AttestationFormValues } from '../../types.ts';
import { attestationSchema } from './attestationSchema.ts';
import { AppContext } from '../../AppContext.tsx';
import { useGetApplicationDetails } from '../../queries/useGetApplicationDetails.ts';
import { usePostCommitApplication } from '../../queries/usePostCommitApplication.ts';
import messages from './CustomerReview.messages.ts';
import './CustomerReview.scss';
import { useCustomerAuth } from '../../utils/useCustomerAuth.ts';
import config from '@luna-protocol/core/src/utils/feature-flags.json';

const CustomerReview = () => {
  useCustomerAuth({
    to: '/verify_final',
  });
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { loanApplicationID, OEMType, dealerFlowTemplate } = useContext(AppContext);
  const { data: applicationData } = useGetApplicationDetails(loanApplicationID || '');
  const { commitApplication } = usePostCommitApplication();
  const [pending, setPending] = useState(false);

  const initialValues: AttestationFormValues = {
    attestation: false,
    social_security_number: '',
  };

  const handleSubmit = (values: AttestationFormValues) => {
    setPending(true);
    commitApplication(
      {
        social_security_number: values.social_security_number,
      },
      {
        onSuccess: () => {
          setPending(false);
          navigate('/payment_setup');
        },
      },
    );
  };

  const getSerialNumberItemTitle = (serialNumberType: string) => {
    if (OEMType === 'brunswick' || (config.FF_OEMTemplatesEnabled && dealerFlowTemplate?.base_type === 'marine')) {
      switch (serialNumberType) {
        case 'hull-id':
          return formatMessage(messages.hinLabel);
        case 'engine':
          return formatMessage(messages.engineLabel);
        case 'trailer':
          return formatMessage(messages.trailerLabel);
      }
    }
    if (OEMType === 'ezgo') {
      return formatMessage(messages.serialLabel);
    }
    return formatMessage(messages.vinLabel);
  };

  const getBaseProductPrice = useMemo(() => {
    return applicationData?.serial_numbers.reduce((acc, serialNumber) => {
      return acc + serialNumber.product_price;
    }, 0);
  }, [applicationData]);

  const getAdditionsTotal = useMemo(() => {
    return applicationData?.deal_information.additions.reduce((acc, e) => {
      return acc + (e.value || 0);
    }, 0);
  }, [applicationData]);

  const getBackendProductsTotal = useMemo(() => {
    return applicationData?.deal_information.backend_products.reduce((acc, e) => {
      return acc + (e.value || 0);
    }, 0);
  }, [applicationData]);

  const getAccessoriesTotal = useMemo(() => {
    return applicationData?.deal_information.accessories.reduce((acc, e) => {
      if (e.is_financeable) {
        return acc + (e.price || 0);
      } else {
        return acc;
      }
    }, 0);
  }, [applicationData]);

  const getDeductionsTotal = useMemo(() => {
    return applicationData?.deal_information.deductions.reduce((acc, e) => {
      return acc + (e.value || 0);
    }, 0);
  }, [applicationData]);

  const getRemainingBalance = useMemo(() => {
    return (
      (getBaseProductPrice || 0) +
      (getAdditionsTotal || 0) +
      (getBackendProductsTotal || 0) +
      (getAccessoriesTotal || 0) -
      (getDeductionsTotal || 0) * -1 -
      (applicationData?.borrowing_amount || 0)
    );
  }, [
    getBackendProductsTotal,
    getAccessoriesTotal,
    getDeductionsTotal,
    getAdditionsTotal,
    getBaseProductPrice,
    applicationData,
  ]);

  return (
    <>
      <Banner alternate>{formatMessage(messages.title)}</Banner>
      <Body fullWidth stretched>
        <h1 className="customer-review--title">{formatMessage(messages.subtitle)}</h1>
        <div className="customer-review--container">
          <div className="inner-container left">
            <h2>{formatMessage(messages.customerTitle)}</h2>
            <Card>
              <table className={'property-table'}>
                <tbody>
                  <tr>
                    <td className={'label'}>{formatMessage(messages.customerName)}</td>
                    <td className={'value'}>{FormatTitleCase(applicationData?.customer_details.name || '')}</td>
                  </tr>
                  <tr>
                    <td className={'label'}>{formatMessage(messages.customerEmail)}</td>
                    <td className={'value'}>{applicationData?.customer_details.email}</td>
                  </tr>
                  <tr>
                    <td className={'label'}>{formatMessage(messages.customerPhone)}</td>
                    <td className={'value'}>
                      {FormatPhoneNumber(applicationData?.customer_details.phone_number_cell || '')}
                    </td>
                  </tr>
                  {applicationData?.customer_details.coapp_name && (
                    <tr>
                      <td className={'label'}>{formatMessage(messages.coapplicantName)}</td>
                      <td className={'value'}>{FormatTitleCase(applicationData?.customer_details.coapp_name || '')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Card>
            <h2>{formatMessage(messages.orderTitle)}</h2>
            <Card>
              <table className={'property-table'}>
                <tbody>
                  <tr>
                    <td className={'label'}>{formatMessage(messages.itemLabel)}</td>
                    <td className={'value'}>{applicationData?.item}</td>
                  </tr>
                  <tr>
                    <td className={'label'}>{formatMessage(messages.colorLabel)}</td>
                    <td className={'value'}>{applicationData?.color}</td>
                  </tr>
                  <tr>
                    <td className={'label'}>{formatMessage(messages.modelLabel)}</td>
                    <td className={'value'}>{applicationData?.model}</td>
                  </tr>
                  {applicationData?.serial_numbers.map(serialNumber => (
                    <tr>
                      <td className={'label'}>{getSerialNumberItemTitle(serialNumber.serial_number_type)}</td>
                      <td className={'value'}>{serialNumber.serial_number}</td>
                    </tr>
                  ))}
                  <tr>
                    <td className={'label bold'}>{formatMessage(messages.total)}</td>
                    <td className={'value bold'}>{USDollar.format(getBaseProductPrice || 0)}</td>
                  </tr>
                </tbody>
              </table>
            </Card>
            <h2>{formatMessage(messages.financeTitle)}</h2>
            <Card>
              <table className={'property-table'}>
                <tbody>
                  <tr>
                    <td className={'label'}>{formatMessage(messages.totalAmountFinanced)}</td>
                    <td className={'value'}>{USDollar.format(applicationData?.borrowing_amount || 0)}</td>
                  </tr>
                  <tr>
                    <td className={'label'}>{formatMessage(messages.monthlyRepayment)}</td>
                    <td className={'value'}>
                      {USDollar.format(applicationData?.selected_loan_program.monthly_repayment || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td className={'label'}>{formatMessage(messages.repaymentPeriod)}</td>
                    <td className={'value'}>{applicationData?.selected_loan_program.tenor} months</td>
                  </tr>
                  <tr>
                    <td className={'label'}>{formatMessage(messages.apr)}</td>
                    <td className={'value'}>
                      {(Number(applicationData?.selected_loan_program.apr || 0) * 100).toFixed(2)}%
                    </td>
                  </tr>
                  <tr>
                    <td className={'label'}>{formatMessage(messages.dateOfFirstPayment)}</td>
                    <td className={'value'}>{applicationData?.selected_loan_program.date_of_first_repayment}</td>
                  </tr>
                  <tr>
                    <td className={'label'}>{formatMessage(messages.dateOfLastPayment)}</td>
                    <td className={'value'}>{applicationData?.selected_loan_program.date_of_last_repayment}</td>
                  </tr>
                </tbody>
              </table>
            </Card>
            <h2>{formatMessage(messages.feesTaxTitle)}</h2>
            <Card>
              <table className={'property-table'}>
                <tbody>
                  {applicationData?.deal_information.additions.map(addition => (
                    <tr>
                      <td className={'label'}>{addition.key}</td>
                      <td className={'value'}>{USDollar.format(addition.value || 0)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td className={'label bold'}>{formatMessage(messages.total)}</td>
                    <td className={'value bold'}>{USDollar.format(getAdditionsTotal || 0)}</td>
                  </tr>
                </tbody>
              </table>
            </Card>
            <h2>{formatMessage(messages.backendProductsTitle)}</h2>
            <Card>
              <table className={'property-table'}>
                <tbody>
                  {applicationData?.deal_information.backend_products.map((backendProduct, index) => (
                    <tr
                      className={
                        index + 1 === applicationData?.deal_information.backend_products.length ? 'divider' : ''
                      }>
                      <td className={'label'}>{backendProduct.key}</td>
                      <td className={'value'}>{USDollar.format(backendProduct.value || 0.0)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td className={'label bold'}>{formatMessage(messages.total)}</td>
                    <td className={'value bold'}>{USDollar.format(getBackendProductsTotal || 0)}</td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
          <div className="inner-container right">
            <h2>{formatMessage(messages.balanceTitle)}</h2>
            <Card>
              <table className={'property-table'}>
                <tbody>
                  <tr>
                    <td className={'label light'}>{formatMessage(messages.basePurchasePrice)}</td>
                    <td className={'value bold'}>{USDollar.format(getBaseProductPrice || 0)}</td>
                  </tr>
                  <tr>
                    <td className={'label light'}>{formatMessage(messages.feesAndTaxes)}</td>
                    <td className={'value bold'}>{USDollar.format(getAdditionsTotal || 0)}</td>
                  </tr>
                  <tr>
                    <td className={'label light'}>{formatMessage(messages.backendProducts)}</td>
                    <td className={'value bold'}>{USDollar.format(getBackendProductsTotal || 0)}</td>
                  </tr>
                  <tr>
                    <td className={'label light'}>{formatMessage(messages.accessories)}</td>
                    <td className={'value bold'}>{USDollar.format(getAccessoriesTotal || 0)}</td>
                  </tr>
                  {applicationData?.deal_information.deductions.map((d: KVPair) => (
                    <>
                      {Math.abs(d.value || 0) > 0 && (
                        <tr>
                          <td className={'label light'}>{d.key}</td>
                          <td className={'value bold'}>{USDollar.format(Math.abs(d.value || 0))}</td>
                        </tr>
                      )}
                    </>
                  ))}
                  <tr className={'divider'}>
                    <td className={'label light'}>{formatMessage(messages.totalAmountFinanced)}</td>
                    <td className={'value bold'}>-{USDollar.format(applicationData?.borrowing_amount || 0)}</td>
                  </tr>
                  <tr>
                    <td className={'label light'}>{formatMessage(messages.remainingBalance)}</td>
                    <td className={'value bold'}>{USDollar.format(getRemainingBalance)}</td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
        </div>
        <div className={'customer-review--attestation'}>
          <p>{formatMessage(messages.disclaimer)}</p>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={attestationSchema}>
            {({ values, handleChange, handleSubmit, isValid, errors, touched }) => (
              <Form>
                <Input
                  className={'small-input'}
                  label={formatMessage(messages.socialSecurityNumber)}
                  placeholder={formatMessage(messages.socialSecurityNumberPlaceholder)}
                  name="social_security_number"
                  onChange={handleChange}
                  value={values.social_security_number}
                  error={
                    errors.social_security_number && !!touched.social_security_number
                      ? errors.social_security_number
                      : undefined
                  }
                />
                <Checkbox
                  label={formatMessage(messages.attestationCheckbox)}
                  value={values.attestation}
                  onChange={handleChange}
                  name={'attestation'}
                  error={errors.attestation}
                  required={true}
                  disclaimer={formatMessage(messages.checkboxDisclaimer)}
                />

                <Button
                  fullWidth={false}
                  type={'submit'}
                  testId={'complete'}
                  variant={'secondary'}
                  onClick={handleSubmit}
                  pending={pending}
                  disabled={!isValid || (Object.keys(touched).length === 0 && touched.constructor === Object)}>
                  {formatMessage(messages.completeButton)}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Body>
    </>
  );
};

export default CustomerReview;
