import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'denied.title',
    defaultMessage: "We're sorry, the details you have entered do not match.!",
  },
  body: {
    id: 'denied.body',
    defaultMessage:
      'Your application has been declined. You may try again by clicking the button below and starting over.',
  },
  submit: {
    id: 'denied.submit',
    defaultMessage: 'Start again',
  },
});

export default messages;
