import { useIntl } from 'react-intl';

import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak';
import { useContext, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext.tsx';
import { useStartPOI } from '../../queries/useStartPOI.ts';
import { useUpdateBankStatus } from '../../queries/useUpdateBankStatus.ts';
import messages from './POIBank.messages';

const POIBank = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { postStartPOI, data } = useStartPOI();
  const { postUpdateBank } = useUpdateBankStatus();
  const { loanApplicationID } = useContext(AppContext);
  const [shouldOpen, setShouldOpen] = useState(false);

  const { open, ready } = usePlaidLink({
    onSuccess: () => {},
    onEvent: eventName => {
      switch (eventName) {
        case 'BANK_INCOME_INSIGHTS_COMPLETED':
          if (loanApplicationID === undefined) return;
          postUpdateBank(loanApplicationID, {
            onSuccess: () => {
              navigate('/proof_of_income/complete');
            },
          });
          break;
      }
    },
    onExit: () => {
      navigate('/proof_of_income');
    },
    token: data?.link_token || '',
  });

  const openIFrame = () => {
    if (loanApplicationID === undefined) return;
    postStartPOI(
      {
        loan_application_id: loanApplicationID,
        proof_type: 'bank',
      },
      {
        onSuccess: () => {
          setShouldOpen(true);
        },
      },
    );
    return;
  };

  useEffect(() => {
    if (shouldOpen && ready) {
      open();
    }
  }, [shouldOpen, ready]);

  return (
    <>
      <Banner>{formatMessage(messages.title)} </Banner>
      <Body>
        <p>{formatMessage(messages.detail1)}</p>
        <p>{formatMessage(messages.detail2)}</p>
        <ButtonGroup>
          <Button width={217} onClick={() => navigate(-1)}>
            {formatMessage(messages.back)}
          </Button>
          <Button width={217} onClick={() => openIFrame()}>
            {formatMessage(messages.continue)}
          </Button>
        </ButtonGroup>
        <LineBreak />
        <p>
          {formatMessage(messages.disclaimer)}{' '}
          <strong>
            <a href={'https://plaid.com/how-it-works-for-consumers/'}>{formatMessage(messages.disclaimerMore)}</a>
          </strong>
        </p>
      </Body>
    </>
  );
};

export default POIBank;
