import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import messages from './Frozen.messages';

const Frozen = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Banner>{formatMessage(messages.title)} </Banner>
      <Body>
        <p>
          <FormattedMessage
            id={messages.detail1.id}
            values={{
              bold: chunks => <b>{chunks}</b>,
            }}
          />
        </p>
        <p>{formatMessage(messages.detail2)}</p>
        <NavLink to={'/processing'}>
          <Button testId={'unfrozen-proceed'}>{formatMessage(messages.continue)}</Button>
        </NavLink>
      </Body>
    </>
  );
};

export default Frozen;
