import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import isLoggedIn from '@luna-protocol/core/src/utils/isLoggedIn.ts';
import { getPayloadFromToken } from '@luna-protocol/core/src/utils/getCustomerIdFromToken.ts';
import { AppContext } from '../AppContext.tsx';

type CustomerAuthProps = {
  to?: string;
};

// useCustomerAuth allows the user to sign in using a Magic Link or redirect to the OTP page
// the redirect after OTP leads to the page where the user was initially trying to access
export const useCustomerAuth = (props?: CustomerAuthProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { setDealerID, setLoanApplicationID, setCustomerID, loanApplicationID, customerID } = useContext(AppContext);
  const QueryLoanApplicationID = searchParams.get('loan_application_id');
  const QueryCustomerID = searchParams.get('customer_id');
  const QueryToken = searchParams.get('token');
  const QueryDealerID = searchParams.get('dealer_id');

  useEffect(() => {
    // Order of operations:
    // 1. Magic Link -> if there is a QueryToken, use it to replace whatever is in localStorage/context
    // 2. Invalidation -> if the customerID in the query params is different from the one in localStorage/context, invalidate the current token
    // 3. OTP -> if the user is still not logged in, redirect to the OTP page

    let hasNewToken = false;

    // Magic Link
    if (QueryToken) {
      const payload = getPayloadFromToken(QueryToken);

      // Check if the token is expired
      const currentTime = Math.floor(Date.now() / 1000); // get current time in seconds
      if (payload.exp > currentTime) {
        // token is valid
        localStorage.setItem('user_token', QueryToken);
        hasNewToken = true;
      }
    }

    // Invalidation
    if (QueryCustomerID && customerID && QueryCustomerID !== customerID) {
      localStorage.removeItem('user_token');
    }
    if (QueryLoanApplicationID && loanApplicationID && loanApplicationID !== QueryLoanApplicationID) {
      localStorage.removeItem('user_token');
    }

    //  Update Context
    if (QueryCustomerID) {
      setCustomerID(QueryCustomerID);
    }
    if (QueryLoanApplicationID) {
      setLoanApplicationID(QueryLoanApplicationID);
    }
    if (QueryDealerID) {
      setDealerID(QueryDealerID);
    }

    if (!hasNewToken && !isLoggedIn()) {
      navigate(props?.to || '/verify', {
        state: {
          autoSend: true,
          customerID: QueryCustomerID,
          loanApplicationID: QueryLoanApplicationID,
          to: location.pathname + location.search, // the page the user was trying to access
          blockedTo: location.pathname + location.search, // the page the user was trying to access
        },
      });
    }
  }, []);
};
