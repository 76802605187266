import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useSearchParams } from 'react-router-dom';

import signIcon from '@luna-protocol/core/src/assets/review-icon.svg';
import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton.tsx';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import { Spinner } from '@luna-protocol/core/src/components/Spinner/Spinner';
import { useGetDealerBranding } from '@luna-protocol/core/src/queries/useGetDealerBranding.ts';

import { useInterval } from 'usehooks-ts';
import { AppContext } from '../../AppContext';
import { useGetContract } from '../../queries/useGetContract';
import { usePostContract } from '../../queries/usePostContract';
import { useCustomerAuth } from '../../utils/useCustomerAuth.ts';
import messages from './SignDocs.messages';
import './SignDocs.scss';

const SignDocs = () => {
  useCustomerAuth();
  const { formatMessage } = useIntl();
  const { loanApplicationID, setOEMType } = useContext(AppContext);
  const { postContract, isLoading: isLoadingGenerator } = usePostContract();
  const { getContract } = useGetContract();
  const loaded = useRef(false);
  const [contract_url, setContractUrl] = useState<string | undefined>(undefined);
  const [searchParams] = useSearchParams();
  const { data: dealerBrandingData } = useGetDealerBranding(searchParams.get('dealer_id') || '');

  useEffect(() => {
    if (dealerBrandingData && dealerBrandingData.oem_type) {
      setOEMType(dealerBrandingData.oem_type);
    }
  }, [dealerBrandingData, setOEMType]);

  useEffect(() => {
    if (loanApplicationID && !isLoadingGenerator && !loaded.current) {
      postContract(loanApplicationID, {
        onSuccess: () => {
          loaded.current = true;
        },
      });
    }
  }, [loanApplicationID, postContract, isLoadingGenerator, loaded]);

  useInterval(() => {
    if (loaded.current && loanApplicationID && !contract_url) {
      getContract(loanApplicationID, {
        onSuccess: d => {
          setContractUrl(d.docusign_link);
        },
      });
    }
  }, 1000);

  return (
    <>
      <BackButton path="/payment_setup" inverted />
      <Banner>{formatMessage(messages.title)}</Banner>
      <Body>
        {contract_url ? (
          <>
            <p>{formatMessage(messages.description)}</p>
            <p>{formatMessage(messages.disclaimer)}</p>
            <ButtonGroup align="left">
              <NavLink to={contract_url}>
                <Button disabled={!contract_url}>
                  {formatMessage(messages.button)}
                  <img className="sign-icon" src={signIcon} alt="sign" />
                </Button>
              </NavLink>
            </ButtonGroup>
          </>
        ) : (
          <Spinner color={'black'} />
        )}
      </Body>
    </>
  );
};

export default SignDocs;
