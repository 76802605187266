import axios from 'axios';
import { useQuery } from 'react-query';
import { FLAGS } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export interface StartIDVResponse {
  link_token: string;
}

const getLinkToken = async (loanApplicationID: string): Promise<StartIDVResponse> => {
  const resp = await axios.post(`${getBaseApi()}/pre_qualification/${loanApplicationID}/idv`, null, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get pricing status');
  }
  return resp.data as StartIDVResponse;
};
const useGetLinkToken = (loanApplicationID: string | undefined) => {
  return useQuery<StartIDVResponse, Error, StartIDVResponse>([FLAGS, loanApplicationID], async () => {
    if (loanApplicationID === undefined) {
      throw new Error('Loan application ID is undefined');
    }
    return await getLinkToken(loanApplicationID);
  });
};

export { useGetLinkToken };
