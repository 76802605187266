import * as Yup from 'yup';

export const attestationSchema = Yup.object({
  attestation: Yup.boolean().isTrue('Check the box above to proceed'),
  social_security_number: Yup.string()
    .required('Required')
    .length(9, 'Must be 9 digits')
    .matches(/^\d+$/, 'The field should have digits only')
    .test('positive', 'Invalid SSN', val => Number(val) > 0),
});
