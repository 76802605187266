import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

const usePostContract = () => {
  const mutation = useMutation((loan_application_id: string) => {
    return axios.post(`${getBaseApi()}/consumer/application/${loan_application_id}/contract`, null, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
  });

  return {
    postContract: mutation.mutate,
    ...mutation,
  };
};

export { usePostContract };
