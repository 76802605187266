import axios from 'axios';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { useMutation } from 'react-query';
import { UpdateCustomerDetails } from '../types';
import { CUSTOMER_DETAILS } from './constants';

export interface UpdateCustomerDetailsResponse {}

const submitDetails = async (customerID: string, data: UpdateCustomerDetails) => {
  const resp = await axios.put(`${getBaseApi()}/pre_qualification/customer/${customerID}`, JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (!resp || resp.status !== 200) {
    throw new Error('Error updating customer details');
  }

  return resp.data as UpdateCustomerDetailsResponse;
};

const usePostCustomerDetails = () => {
  const mutation = useMutation<UpdateCustomerDetailsResponse, Error, [string, UpdateCustomerDetails]>(
    [CUSTOMER_DETAILS],
    variables => submitDetails(...variables),
  );

  return {
    submitDetails: mutation.mutate,
    ...mutation,
  };
};

export { usePostCustomerDetails };
