import { useIntl } from 'react-intl';

import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak';
import { Spinner } from '@luna-protocol/core/src/components/Spinner/Spinner';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { useGetFlags } from '../../queries/useGetFlags.ts';
import { useGetPricingStatus } from '../../queries/useGetPricingStatus';
import { useCustomerAuth } from '../../utils/useCustomerAuth.ts';
import messages from './Flags.messages';
import './Flags.scss';

// The flags page is reachable from emails (like Review), therefore we must first ensure they are logged in.
// It is important this page does NOT use StartPricing, as that will reset the loan application
const Flags = () => {
  useCustomerAuth();
  const { formatMessage } = useIntl();
  const { loanApplicationID, setPricingStatus } = useContext(AppContext);
  const { data: pricingData, refetch: refetchStatus, isSuccess: hasData } = useGetPricingStatus(loanApplicationID);
  const { data: flags, refetch: refetchFlags, isSuccess: hasFlagData } = useGetFlags(loanApplicationID);
  const navigate = useNavigate();

  useEffect(() => {
    refetchStatus();
    refetchFlags();
    if ((hasData && hasFlagData) || (flags?.flags.length || 0) > 0) {
      if (pricingData?.status === 'prequal-review') {
        navigate('/under_review');
        return;
      } else if (pricingData?.status === 'prequal-blocking-flag-raised' && flags?.flags && flags.flags.length > 0) {
        // there are three types of blocking flags: freeze, mismatch, and redacted
        if (flags.flags.some(flag => flag.type === 'redacted' && flag.status === 'new')) {
          navigate('/under_review');
          return;
        } else if (flags.flags.some(flag => flag.type === 'security_freeze' && flag.status === 'new')) {
          navigate('/frozen');
          return;
        } else {
          navigate('/confirm_details');
          return;
        }
      } else {
        // assume approval
        // unlike Processing, we want to handle flags even for approvals
        if (flags?.flags && flags.flags.length > 0) {
          if (flags.flags.some(flag => flag.type === 'identity_verification' && flag.status === 'new')) {
            navigate('/stipulation_review');
            return;
          }
          if (flags.flags.some(flag => flag.type === 'proof_of_income' && flag.status === 'new')) {
            navigate('/proof_of_income');
            return;
          }
        }

        // otherwise if a customer got to this page, somehow - just show them their prequalified amount again
        setPricingStatus(pricingData);
        navigate('/approval');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingData, navigate, flags, refetchFlags, refetchStatus, hasData, hasFlagData]);

  return (
    <>
      <Banner>
        <Spinner />
        <LineBreak />
        <p className="processing-message">{formatMessage(messages.processing)}</p>
      </Banner>
    </>
  );
};

export default Flags;
