import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'CoAppComplete.title',
    defaultMessage:
      'Thank you for completing your pre-qualification. You will receive your pre-qualification decision via email once all parties have completed their pre-qualification.',
  },
  body: {
    id: 'CoAppComplete.body',
    defaultMessage: 'You can close this page.',
  },
});

export default messages;
