const PRICING_STATUS = 'pricing-status';
const LEGAL_TERMS = 'legal-terms';
const PRIMARY_APPLICATION = 'primary-application';
const COAPPLICANT_APPLICATION = 'coapplicant-application';
const SEND_CODE = 'send-code';
const START_PRICING = 'start-pricing';
const DEALER_LOCATIONS = 'dealer-locations';
const NOTIFY_DEALER = 'notify-dealer';
const SEND_REFERENCE = 'send-reference';
const APPLICATION_CONTRACT = 'application-contract';
const FLAGS = 'flags';
const DOCUSIGN_LINK = 'docusign-link';
const ACH_DETAILS = 'ach-details';
const ACH_URL = 'ach-url';
const CUSTOMER_DETAILS = 'customer-details';
const APPLICATION_DETAILS = 'application-details';
const DEALER = 'dealer';
const PRIMARY_NAMES = 'primary-names';
const SEND_COAPPLICANT = 'send-coapplicant';

export {
  PRICING_STATUS,
  LEGAL_TERMS,
  PRIMARY_APPLICATION,
  COAPPLICANT_APPLICATION,
  SEND_CODE,
  START_PRICING,
  DEALER_LOCATIONS,
  NOTIFY_DEALER,
  APPLICATION_CONTRACT,
  FLAGS,
  DOCUSIGN_LINK,
  ACH_DETAILS,
  ACH_URL,
  CUSTOMER_DETAILS,
  DEALER,
  APPLICATION_DETAILS,
  PRIMARY_NAMES,
  SEND_REFERENCE,
  SEND_COAPPLICANT,
};
