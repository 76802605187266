import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

const usePostAccept = () => {
  const mutation = useMutation((loan_application_id: string) => {
    return axios.post(`${getBaseApi()}/pre_qualification/application/${loan_application_id}/accept_prequal`, null, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
  });

  return {
    postAccept: mutation.mutate,
    ...mutation,
  };
};

export { usePostAccept };
