import axios from 'axios';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { useMutation } from 'react-query';
import { UserDetails } from '../types';
import { PRIMARY_APPLICATION } from './constants';

export interface PostPrimaryApplicationResponse {
  customer_id: string;
  loan_application_id: string;
  co_applicant_id?: string;
  primary_token: string;
}

const submitApplication = async (data: UserDetails) => {
  const resp = await axios.post(`${getBaseApi()}/pre_qualification/primary_application_form`, JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json' },
  });
  if (!resp || resp.status !== 200) {
    throw new Error('Error submitting application');
  }

  return resp.data as PostPrimaryApplicationResponse;
};

const usePostPrimaryApplication = () => {
  const mutation = useMutation<PostPrimaryApplicationResponse, Error, UserDetails>({
    mutationKey: [PRIMARY_APPLICATION],
    mutationFn: submitApplication,
  });

  return {
    submitApplication: mutation.mutate,
    ...mutation,
  };
};

export { usePostPrimaryApplication };
