import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'pricing_error.title',
    defaultMessage: 'Sorry, there was a problem retrieving the credit decision',
  },
  detail1: {
    id: 'pricing_error.detail1',
    defaultMessage:
      'Unfortunately there was an error with the credit decisioning process. Please contact your dealer to try again.',
  },
});

export default messages;
