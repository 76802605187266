import axios from 'axios';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { useMutation } from 'react-query';
import { useContext } from 'react';
import { AppContext } from '../AppContext.tsx';

interface CommitApplicationRequest {
  social_security_number: string;
}

const usePostCommitApplication = () => {
  const { loanApplicationID } = useContext(AppContext);

  const mutation = useMutation((data: CommitApplicationRequest) => {
    return axios.post(`${getBaseApi()}/consumer/application/${loanApplicationID}/commit`, data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
  });

  return {
    commitApplication: mutation.mutate,
    ...mutation,
  };
};
export { usePostCommitApplication };
