import axios from 'axios';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { useQuery } from 'react-query';
import { ACH_DETAILS } from './constants';

interface PostACHDetailsParams {
  account_number: string | undefined;
  routing_number: string | undefined;
}

const getACHContract = async (loan_application_id: string) => {
  const resp = await axios.get(`${getBaseApi()}/consumer/application/${loan_application_id}/ach`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('user_token')}`,
    },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get ACH details');
  }
  return resp.data as PostACHDetailsParams;
};

const useGetACHDetails = (loan_application_id: string) => {
  return useQuery([ACH_DETAILS], () => {
    return getACHContract(loan_application_id);
  });
};
export { useGetACHDetails };
