import * as Yup from 'yup';

const minAge = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

export const confirmationSchema = Yup.object({
  isFirstName: Yup.boolean(),
  firstName: Yup.string()
    .test('len', 'Must more than 1 character', val => (val ? val.length > 1 : true))
    .when('isFirstName', {
      is: true,
      then: (schema: Yup.StringSchema<string | undefined>) => schema.required('Please provide a first name'),
      otherwise: (schema: Yup.StringSchema<string | undefined>) => schema.notRequired(),
    }),
  isLastName: Yup.boolean(),
  lastName: Yup.string()
    .test('len', 'Must more than 1 character', val => (val ? val.length > 1 : true))
    .when('isLastName', {
      is: true,
      then: (schema: Yup.StringSchema<string | undefined>) => schema.required('Please provide a last name'),
      otherwise: (schema: Yup.StringSchema<string | undefined>) => schema.notRequired(),
    }),
  isStreetAddress: Yup.boolean(),
  streetAddress: Yup.string().when('isStreetAddress', {
    is: true,
    then: (schema: Yup.StringSchema<string | undefined>) => schema.required('Please provide a Street Address'),
    otherwise: (schema: Yup.StringSchema<string | undefined>) => schema.notRequired(),
  }),
  isCity: Yup.boolean(),
  city: Yup.string().when('isCity', {
    is: true,
    then: (schema: Yup.StringSchema<string | undefined>) => schema.required('Please provide a city'),
    otherwise: (schema: Yup.StringSchema<string | undefined>) => schema.notRequired(),
  }),
  isState: Yup.boolean(),
  state: Yup.object(),
  isZipCode: Yup.boolean(),
  zipCode: Yup.string()
    .test('len', 'Must be exactly 5 or 10 characters', val => val?.length === 5 || val?.length === 10)
    .when('isZipCode', {
      is: true,
      then: (schema: Yup.StringSchema<string | undefined>) => schema.required('Please provide a zip code'),
      otherwise: (schema: Yup.StringSchema<string | undefined>) => schema.notRequired(),
    }),
  isSocial_security_number: Yup.boolean(),
  social_security_number: Yup.string()
    .required('Please provide a social security number')
    .matches(/^\d+$/, 'The field should have digits only')
    .test('len', 'Must be exactly 9 or 4 characters', val => val?.length === 9 || val?.length === 4),
  isDateOfBirth: Yup.boolean(),
  dateOfBirth: Yup.date()
    .min(new Date(1900, 0, 1), 'Invalid date')
    .max(minAge(), 'Invalid date')
    .when('fullssn', {
      is: true,
      then: (schema: Yup.DateSchema<Date | undefined>) => schema.required('Please provide your date of birth'),
      otherwise: (schema: Yup.DateSchema<Date | undefined>) => schema.notRequired(),
    }),
});
