import axios from 'axios';

import { useMutation } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { PostVerificationConsumerRequest, PostVerificationConsumerResponse } from '@luna-protocol/core/src/types';

const usePostVerification = () => {
  const mutation = useMutation<PostVerificationConsumerResponse, Error, PostVerificationConsumerRequest>(
    async (data: PostVerificationConsumerRequest) => {
      const resp = await axios.post(`${getBaseApi()}/auth/otp/verify_loan_application`, JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' },
      });
      if (!resp || resp.status !== 200) {
        throw new Error('Invalid verification code');
      }
      localStorage.setItem('user_token', resp.data.token);
      return resp.data as PostVerificationConsumerResponse;
    },
  );

  return {
    postAuth: mutation.mutate,
    ...mutation,
  };
};

export { usePostVerification };
