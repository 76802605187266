import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { ACH_DETAILS } from './constants';
import { useContext } from 'react';
import { AppContext } from '../AppContext.tsx';

interface PostACHDetailsParams {
  account_number: string | undefined;
  routing_number: string | undefined;
}

const usePostACHDetails = () => {
  const { loanApplicationID } = useContext(AppContext);

  const mutation = useMutation([ACH_DETAILS], (data: PostACHDetailsParams) => {
    return axios.post(`${getBaseApi()}/consumer/application/${loanApplicationID}/ach`, JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });
  });

  return {
    postDetails: mutation.mutate,
    ...mutation,
  };
};

export { usePostACHDetails };
