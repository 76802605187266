import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'frozen.title',
    defaultMessage: "Your customer's credit details cannot be retrieved due to a freeze on their credit file.",
  },
  detail1: {
    id: 'frozen.detail1',
    defaultMessage:
      'Please instruct the customer to contact TransUnion at <bold>800-916-8800</bold> or click Unfreeze your TransUnion account to unfreeze their account.',
  },
  detail2: {
    id: 'frozen.detail2',
    defaultMessage:
      'An email has been sent to your customer with a link enclosed. Once they have unfrozen your account, they can follow the link, or click on the button below, to receive the credit decision.',
  },
  continue: {
    id: 'frozen.continue',
    defaultMessage: 'Account Unfrozen',
  },
});

export default messages;
