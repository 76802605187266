import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'poi_select.title',
    defaultMessage: 'Proof of income',
  },
  description: {
    id: 'poi_select.description',
    defaultMessage: 'Please select how you receive your income:',
  },
  bank: {
    id: 'poi_select.bank',
    defaultMessage: 'Most of my income is deposited into my bank on a regular basis.',
  },
  document: {
    id: 'poi_select.document',
    defaultMessage:
      'Most of my income is not direct deposited on a regular basis, but I can provide a paystub, W2, or 1099.',
  },
  continue: {
    id: 'poi_select.continue',
    defaultMessage: 'Continue',
  },
});

export default messages;
