import Complete from '@luna-protocol/core/src/components/Complete/Complete';
import { useIntl } from 'react-intl';
import messages from './CoAppComplete.messages.ts';

const CoAppComplete = () => {
  const { formatMessage } = useIntl();

  return <Complete title={formatMessage(messages.title)} body={[formatMessage(messages.body)]} />;
};

export default CoAppComplete;
