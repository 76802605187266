import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'confirm_details.title',
    defaultMessage: 'Please re-review your information below for accuracy',
  },
  detail1: {
    id: 'confirm_details.detail1',
    defaultMessage: "Please check your details below, make any necessary revisions, and click 'Submit'.",
  },
  firstName: {
    id: 'confirm_details.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'confirm_details.lastName',
    defaultMessage: 'Last name',
  },
  streetAddress: {
    id: 'confirm_details.streetAddress',
    defaultMessage: 'Street address',
  },
  city: {
    id: 'confirm_details.city',
    defaultMessage: 'City',
  },
  state: {
    id: 'confirm_details.state',
    defaultMessage: 'State',
  },
  zipCode: {
    id: 'confirm_details.zipCode',
    defaultMessage: 'Zip code',
  },
  ssn: {
    id: 'confirm_details.ssn',
    defaultMessage: 'Full social security number (SSN)',
  },
  dateOfBirth: {
    id: 'confirm_details.dateOfBirth',
    defaultMessage: 'Date of birth',
  },
  submit: {
    id: 'confirm_details.submit',
    defaultMessage: 'Continue',
  },
  returnButton: {
    id: 'application.return',
    defaultMessage: 'Return to Homepage',
  },
  submitSuccess: {
    id: 'application.submitSuccess',
    defaultMessage: 'Your information has been submitted successfully.',
  },
  retryError: {
    id: 'application.retryError',
    defaultMessage: 'Your information did not match the results found.  Please try again.',
  },
  remainingTries: {
    id: 'application.remainingTries',
    defaultMessage: 'You have {remainingTries} attempts remaining.',
  },
});

export default messages;
