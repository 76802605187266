import axios from 'axios';
import { useQuery } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { LEGAL_TERMS } from './constants';

export type LegalTermType = 'ElectronicDisclosuresPolicy' | 'TermsOfUse' | 'CreditReportAuthorization';

export interface LegalTerm {
  id: string;
  url: string;
  document_type: LegalTermType;
  file_extension: string;
  file_contents?: string;
}

const getLegalTerms = async () => {
  const data = await axios.get(`${getBaseApi()}/pre_qualification/legal_terms`);
  if (!data) {
    throw new Error('Failed to read Legal Terms');
  }

  // parse each LegalTerm, and if the file_extension is md, then fetch the url and store the contents in the LegalTer
  const legalTerms: LegalTerm[] = data.data.legal_terms;
  const promises = legalTerms.map(async legalTerm => {
    if (legalTerm.file_extension === 'md') {
      const fileContents = await axios.get(legalTerm.url);
      legalTerm.file_contents = fileContents.data;
    }
  });
  await Promise.all(promises);

  return legalTerms;
};

const useGetLegalTerms = () => {
  return useQuery<LegalTerm[], Error, LegalTerm[]>([LEGAL_TERMS], async () => {
    return await getLegalTerms();
  });
};

export { useGetLegalTerms };
