import axios from 'axios';
import { useQuery } from 'react-query';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { PRIMARY_NAMES } from './constants';

interface PrimaryNames {
  first_name: string;
  last_name: string;
  borrowing_amount: number;
}

const getPrimaryNames = async (loan_application_id: string) => {
  const resp = await axios.get(`${getBaseApi()}/pre_qualification/${loan_application_id}/primary_customer_names`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get primary names');
  }

  return resp.data as PrimaryNames;
};

const useGetPrimaryNames = (loan_application_id: string) => {
  return useQuery(
    [PRIMARY_NAMES],
    () => {
      return getPrimaryNames(loan_application_id);
    },
    {
      enabled: !!loan_application_id,
    },
  );
};

export { useGetPrimaryNames };
