import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'poi_complete.title',
    defaultMessage: 'Your documents are being reviewed.',
  },
  detail1: {
    id: 'poi_complete.detail1',
    defaultMessage:
      'Your proof of income documents are being reviewed, which can take up to 15 minutes. Once the review is complete, you will receive an email with instructions on next steps.',
  },
});

export default messages;
