import { ChakraProvider } from '@chakra-ui/react';
import '@luna-protocol/core/src/App.css';
import i18n from '@luna-protocol/core/src/i18n';
import { InitDatadog } from '@luna-protocol/core/src/utils/InitDatadog';
import createRouter from '@luna-protocol/core/src/utils/createRouter';
import { useEffect, useState } from 'react';
import { IntlProvider, ResolvedIntlConfig } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RouterProvider } from 'react-router-dom';
import { AppContextProvider } from './AppContext';
import routes from './routes.tsx';

if (!import.meta.env.DEV) {
  InitDatadog('ascent-consumer-app');
}

// Create a client
const queryClient = new QueryClient();

const App = ({ initialEntries }: { initialEntries?: string[] }) => {
  const [locale] = useState(i18n.defaultLocale);
  const [messages, setMessages] = useState<ResolvedIntlConfig['messages']>();

  useEffect(() => {
    /* Fetching the localized messages for the specified locale using the `i18n` object. */
    i18n.getMessages(locale).then(async data => {
      if (data) {
        setMessages(data);
      }
    });
  }, [locale]);

  return (
    <AppContextProvider>
      <ChakraProvider
        toastOptions={{ defaultOptions: { position: 'bottom' } }}
        resetCSS={false}
        disableGlobalStyle={true}>
        <IntlProvider locale={i18n.defaultLocale} defaultLocale={i18n.defaultLocale} messages={messages}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={createRouter(routes, initialEntries)} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </IntlProvider>
      </ChakraProvider>
    </AppContextProvider>
  );
};

export default App;
