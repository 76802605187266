import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';

import { AppContext } from '../../AppContext.tsx';
import { useSendCoapplicantRequest } from '../../queries/useSendCoapplicantRequest.ts';
import messages from './WaitingForCoapplicant.messages';

const WaitingForCoapplicant = () => {
  const { formatMessage } = useIntl();
  const { coapplicantID, loanApplicationID } = useContext(AppContext);
  const { sendCoapplicant, isSuccess } = useSendCoapplicantRequest();

  useEffect(() => {
    if (coapplicantID && loanApplicationID && !isSuccess) {
      sendCoapplicant({
        loan_application_id: loanApplicationID,
        customer_id: coapplicantID,
      });
    }
  }, [coapplicantID, loanApplicationID, isSuccess]);
  return (
    <>
      <Banner>{formatMessage(messages.title)} </Banner>
      <Body>
        <p>{formatMessage(messages.detail1)}</p>
        <p>{formatMessage(messages.detail2)}</p>
      </Body>
    </>
  );
};

export default WaitingForCoapplicant;
