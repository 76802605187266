import axios from 'axios';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { useMutation } from 'react-query';
import { CoapplicantDetails } from '../types';
import { COAPPLICANT_APPLICATION } from './constants';

const submitApplication = async (data: CoapplicantDetails) => {
  const resp = await axios.post(
    `${getBaseApi()}/pre_qualification/coapplicant_application_form`,
    JSON.stringify(data),
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    },
  );
  if (!resp || resp.status !== 200) {
    throw new Error('Error submitting application');
  }

  return resp.data;
};

const usePostCoapplicantApplication = () => {
  const mutation = useMutation({
    mutationKey: [COAPPLICANT_APPLICATION],
    mutationFn: submitApplication,
  });

  return {
    submitCoApplication: mutation.mutate,
    ...mutation,
  };
};

export { usePostCoapplicantApplication };
