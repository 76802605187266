import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'idv.title',
    defaultMessage: 'Please have a form of identification ready, as shortly you will be asked to prove your identity',
  },
  description: {
    id: 'idv.description',
    defaultMessage: 'Please have a form of identification ready, as shortly you will be asked to prove your identity',
  },
});

export default messages;
