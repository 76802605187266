import axios from 'axios';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { useQuery } from 'react-query';
import { APPLICATION_DETAILS } from './constants';
import { DealInformation } from '@luna-protocol/core/src/types.ts';

interface GetApplicationDetailsResponse {
  borrowing_amount: number;
  item: string;
  color: string;
  model: string;
  customer_details: CustomerDetails;
  selected_loan_program: LoanProgram;
  deal_information: DealInformation;
  serial_numbers: SerialNumber[];
}

export interface CustomerDetails {
  name: string;
  email: string;
  phone_number_cell: string;
  coapp_name?: string;
}

export interface LoanProgram {
  monthly_repayment: number;
  tenor: number;
  apr: number;
  date_of_first_repayment: string;
  date_of_last_repayment: string;
}

export interface SerialNumber {
  serial_number: string;
  serial_number_type: string;
  product_price: number;
}

const getApplicationDetails = async (loan_application_id: string) => {
  const resp = await axios.get(`${getBaseApi()}/consumer/application/${loan_application_id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('user_token')}`,
    },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get application details');
  }
  return resp.data as GetApplicationDetailsResponse;
};

const useGetApplicationDetails = (loan_application_id: string) => {
  return useQuery([APPLICATION_DETAILS, loan_application_id], () => {
    return getApplicationDetails(loan_application_id);
  });
};
export { useGetApplicationDetails };
