import * as Yup from 'yup';

const MIN_ACCOUNT_NUMBER_LENGTH = 7;
const MAX_ACCOUNT_NUMBER_LENGTH = 17;

export const accountDetailSchema = Yup.object().shape({
  auto_payments: Yup.boolean(),
  routing_number: Yup.string()
    .matches(/^[0-9]{9}$/, 'Invalid routing number. Must be a 9-digit number.')
    .when('auto_payments', {
      is: true,
      then: (schema: Yup.StringSchema<string | undefined>) => schema.required('Please provide a routing number'),
      otherwise: (schema: Yup.StringSchema<string | undefined>) => schema.notRequired(),
    }),
  account_number: Yup.string()
    .matches(/^[0-9]+$/, 'Invalid account number. Must be between 10-12 digits')
    .min(MIN_ACCOUNT_NUMBER_LENGTH, 'Must be at least 7 characters')
    .max(MAX_ACCOUNT_NUMBER_LENGTH, 'Must be at most 17 characters')
    .when('auto_payments', {
      is: true,
      then: (schema: Yup.StringSchema<string | undefined>) => schema.required('Please provide an account number'),
      otherwise: (schema: Yup.StringSchema<string | undefined>) => schema.notRequired(),
    }),
});
