import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import { useIntl } from 'react-intl';
import messages from './InstructionPage.messages';

const InstructionPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Banner>{formatMessage(messages.title)}</Banner>
      <Body stretched>
        <ButtonGroup align="left">
          <Button width={217} onClick={() => {}} variant="secondary">
            {formatMessage(messages.button)}
          </Button>
        </ButtonGroup>
      </Body>
    </>
  );
};

export default InstructionPage;
