import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import axios from 'axios';
import { useQuery } from 'react-query';
import { ACH_URL } from './constants';

interface ACHAgreementUrlParams {
  url: 'string';
}

const getACHContractUrl = async () => {
  const resp = await axios.get(`${getBaseApi()}/pre_qualification/legal_terms/ach`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('user_token')}`,
    },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get ACH details');
  }
  console.log(resp.data.ach_agreement);
  return resp.data.ach_agreement as ACHAgreementUrlParams;
};

const useGetACHAgreementUrl = () => {
  return useQuery([ACH_URL], () => {
    return getACHContractUrl();
  });
};
export { useGetACHAgreementUrl };
