import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import poweredbyaquaLogo from '@luna-protocol/core/src/assets/poweredbyaqua.svg';
import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import { USDollar } from '@luna-protocol/core/src/utils/constants/format.ts';
import { useGetDealerBranding } from '@luna-protocol/core/src/queries/useGetDealerBranding.ts';

import { AppContext } from '../../AppContext';
import messages from './Approval.messages';
import './Approval.scss';
import { useSearchParams } from 'react-router-dom';

const Approval = () => {
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const { pricingStatus, isDealerDevice, setOEMType } = useContext(AppContext);
  const { isLoading, data } = useGetDealerBranding(searchParams.get('dealer_id') || '');

  useEffect(() => {
    if (data && data.oem_type) {
      setOEMType(data.oem_type);
    }
  }, [data, setOEMType]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Banner>{formatMessage(messages.title)}</Banner>
      <Body>
        <div className="approval-container">
          <div className="poweredbyaqua-logo">
            <img src={poweredbyaquaLogo} alt="logo" />
          </div>
          <div className="prequal-amount-container">
            <p>{formatMessage(messages.amountTitle)}</p>
            <p className="amount">{pricingStatus?.amount !== undefined ? USDollar.format(pricingStatus.amount) : ''}</p>
          </div>
          <p className="amount">
            {pricingStatus?.amount !== undefined &&
              (!isDealerDevice
                ? formatMessage(messages.amountDetails, {
                    amount: USDollar.format(pricingStatus.amount),
                  })
                : formatMessage(messages.dealerDeviceamountDetails, {
                    amount: USDollar.format(pricingStatus.amount),
                  }))}
          </p>
        </div>
      </Body>
    </>
  );
};

export default Approval;
