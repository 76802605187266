import { SelectOption } from '@luna-protocol/core/src/types';

export interface User {
  user_id?: string;
  token?: string;
  details?: {
    first_name?: string;
    last_name?: string;
    email_address?: string;
  };
  last_signin?: string;
}

export interface SerialNumberArray {
  serial_number: Collateral[];
}

export interface CoApplicantDetails {
  email_address: string | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
}

export interface Address {
  address_lines: string[];
  city: string;
  state: string;
  zip_code: string;
}

export interface LegalTerms {
  consent_cra_policy: string;
  consent_esign_disclosure: string;
  consent_terms_and_conditions: string;
}

export interface UserDetails {
  user_locale: string;
  address: { address_lines: string[]; city: string; state: string | undefined; zip_code: string };
  annual_gross_income: number;
  co_applicant: CoApplicantDetails | null;
  date_of_birth: string | undefined;
  residence_type: string | undefined;
  last_name: string;
  serial_number: Collateral[] | undefined;
  years_in_current_address: number | undefined;
  phone_number_home: null | string;
  email_address: string;
  phone_number_cell: string;
  social_security_number: string;
  source_of_application: string;
  years_in_current_employment: number | undefined;
  first_name: string;
  legal_terms: { consent_esign_disclosure: string; consent_terms_and_conditions: string; consent_cra_policy: string };
  dealer_id: string | undefined;
  paper_application: boolean;
}

export interface CoapplicantDetails {
  loan_application_id: string;
  customer_details: {
    first_name: string;
    last_name: string;
    annual_gross_income: number;
    phone_number_cell: string;
    phone_number_home: null | string;
    social_security_number: string;
    date_of_birth: string | undefined;
    address: {
      address_lines: string[];
      city: string;
      state: string | undefined;
      county: string | undefined;
      zip_code: string;
    };
    years_in_current_employment: number | undefined;
    years_in_current_address: number | undefined;
    residence_type: string | undefined;
  };
  legal_terms: { consent_esign_disclosure: string; consent_terms_and_conditions: string; consent_cra_policy: string };
}

export interface UpdateCustomerDetails {
  first_name?: string;
  last_name?: string;
  social_security_number?: string;
  date_of_birth?: string;
  address?: { address_lines: [string | undefined]; city?: string; state?: string; zip_code?: string };
}

export enum ResidenceType {
  OWN = 'own',
  RENT = 'rent',
}

export const residenceTypeDisplayValues: SelectOption[] = [
  { label: 'Home Owner', value: ResidenceType.OWN },
  { label: 'Renter', value: ResidenceType.RENT },
];

export interface ApplicationFormValues {
  firstName: string;
  lastName: string;
  streetAddress: string;
  city: string;
  state?: SelectOption;
  county?: string;
  zipCode: string;
  phoneNumber: string;
  homeNumber: string;
  emailAddress: string;
  residenceType?: SelectOption;
  yearsInCurrentResidence?: number;
  yearsInCurrentEmployment?: number;
  dateOfBirth?: string;
  grossIncome?: number;
  coApplicant: boolean;
  ssn: string;
  fullssn?: boolean;
  consentAgreement: boolean;
  consentData: boolean;
  termsAgreement: boolean;
  collateral: Collateral[];
  coapplicantFirstName?: string;
  coapplicantLastName?: string;
  coapplicantEmailAddress?: string;
  testingData?: string;
  primaryFirstName?: string;
  primaryLastName?: string;
  amountRequested?: string;
  paper_application?: boolean | null;
}
export interface Collateral {
  price: number;
  serial_number: string;
  serial_number_type: string;
  serial_number_asset_class: string;
  make: string;
  model: string;
  year: number;
  condition?: 'new' | 'used';
  colour?: string;
  nada_msrp_invoice: number;
}

export interface ConfirmDetailsFormValues {
  firstName?: string;
  isFirstName: boolean;
  lastName?: string;
  isLastName: boolean;
  streetAddress?: string;
  isStreetAddress: boolean;
  city?: string;
  isCity: boolean;
  state?: SelectOption;
  isState: boolean;
  zipCode?: string;
  isZipCode: boolean;
  dateOfBirth?: string;
  isDateOfBirth: boolean;
  social_security_number?: string;
  isSocial_security_number: boolean;
}

export interface AuthCodeValues {
  authCode0?: string;
  authCode1?: string;
  authCode2?: string;
  authCode3?: string;
  authCode4?: string;
  authCode5?: string;
}

export type VerificationCode = string;
export type CustomerId = string;

export interface DealerLocation {
  dealer_location_id: string;
  friendly_name: string;
  full_address: string;
  distance_miles: number;
}

export interface AttestationFormValues {
  attestation: boolean;
  social_security_number: string;
}

export type FlowTemplate = {
  promotional_logo: string;
  warranty_link?: string;
  warranty_text?: string;
  id: string;
  name: string;
  base_type: string;
  unit_identifier: string;
  seller_purchase_price_type: string;
  theme_colour: string;
  primary_logo: string;
  stock_image: string;
  accessory_examples: string[];
  participation_fee: boolean;
  dealer_fee: boolean;
  consumer_only: boolean;
  created_at: string;
  updated_at: string;
};
