import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './PricingError.messages';

const PricingError = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Banner>{formatMessage(messages.title)} </Banner>
      <Body>
        <p>
          <FormattedMessage
            id={messages.detail1.id}
            values={{
              bold: chunks => <b>{chunks}</b>,
            }}
          />
        </p>
      </Body>
    </>
  );
};

export default PricingError;
