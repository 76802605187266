import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'stipulation.title',
    defaultMessage: 'We just need to verify your identity.',
  },
  body: {
    id: 'stipulation.body',
    defaultMessage:
      'Verify your identity instantly by uploading identification documents using the link we sent in your email.',
  },
  button: {
    id: 'stipulation.button',
    defaultMessage: 'Continue',
  },
  modalTitle: {
    id: 'stipulation.modal.title',
    defaultMessage: 'Did you know...',
  },
  modalBody: {
    id: 'stipulation.modal.body',
    defaultMessage:
      "In order to speed up the in-store approvals process, we recommend completing the Identity Verification process at this stage. If you wish to complete the process later down the line, please select 'skip and do later.' This process is powered by Plaid.",
  },
  modalQuestion: {
    id: 'stipulation.modal.question',
    defaultMessage:
      "If you want to find out more about Plaid's use of customer data and services, please use the resources linked below:",
  },
  privacyPolicy: {
    id: 'stipulation.modal.privacyPolicy',
    defaultMessage: 'end user privacy policy',
  },
  helpCenter: {
    id: 'stipulation.modal.helpCenter',
    defaultMessage: 'consumer help center',
  },
});

export default messages;
