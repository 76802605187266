import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { SEND_COAPPLICANT } from './constants';

interface SendCoapplicantRequest {
  loan_application_id: string;
  customer_id: string;
}

const useSendCoapplicantRequest = () => {
  const mutation = useMutation({
    mutationKey: [SEND_COAPPLICANT],
    mutationFn: (data: SendCoapplicantRequest) => {
      return axios.post(
        `${getBaseApi()}/pre_qualification/notify_coapplicant_information_request`,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('user_token')}`,
          },
        },
      );
    },
  });

  return {
    sendCoapplicant: mutation.mutate,
    ...mutation,
  };
};

export { useSendCoapplicantRequest };
