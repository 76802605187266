import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'waiting_for_coapplicant.title',
    defaultMessage: 'Waiting for the co-applicant.',
  },
  detail1: {
    id: 'waiting_for_coapplicant.detail1',
    defaultMessage:
      'An email has been sent to the co-applicant requesting their details. They will need to' +
      ' complete the form before the application can proceed.',
  },
  detail2: {
    id: 'waiting_for_coapplicant.detail2',
    defaultMessage:
      'If the primary applicant does not receive an email regarding their credit decision (typically within 15 minutes),' +
      ' please follow up with the co-applicant directly.',
  },
});

export default messages;
