import { useIntl } from 'react-intl';

import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import Body from '@luna-protocol/core/src/components/Body/Body';
import messages from './POIComplete.messages';

const POIComplete = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Banner>{formatMessage(messages.title)} </Banner>
      <Body>
        <p>{formatMessage(messages.detail1)}</p>
      </Body>
    </>
  );
};

export default POIComplete;
