import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export interface UpdateBankStatusResponse {
  approved: boolean;
}

const useUpdateBankStatus = () => {
  const mutation = useMutation(async (loanAppID: string) => {
    const resp = await axios.post(`${getBaseApi()}/pre_qualification/${loanAppID}/poi/bank`, null, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
    });

    if (resp.status !== 200) {
      throw new Error('unable to update bank status');
    }

    return resp.data as UpdateBankStatusResponse;
  });

  return {
    postUpdateBank: mutation.mutate,
    ...mutation,
  };
};

export { useUpdateBankStatus };
