import axios from 'axios';
import { useQuery } from 'react-query';
import { CUSTOMER_DETAILS } from './constants';
import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';
import { Address } from '../types.ts';

export interface CustomerDetails {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  address: Address;
}

const getCustomer = async (customerID: string): Promise<CustomerDetails> => {
  const resp = await axios.get(`${getBaseApi()}/pre_qualification/customer/${customerID}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
  });
  if (resp.status !== 200) {
    throw new Error('Failed to get customer details');
  }
  return resp.data as CustomerDetails;
};
const useGetCustomer = (customerID: string | undefined) => {
  return useQuery<CustomerDetails, Error, CustomerDetails>(
    [CUSTOMER_DETAILS, customerID],
    async () => {
      return await getCustomer(customerID || '');
    },
    {
      enabled: !!customerID,
    },
  );
};

export { useGetCustomer };
