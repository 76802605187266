import { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { Form, useNavigate } from 'react-router-dom';

import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton.tsx';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button.tsx';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup.tsx';
import Checkbox from '@luna-protocol/core/src/components/Checkbox/Checkbox.tsx';
import Input from '@luna-protocol/core/src/components/Input/Input.tsx';
import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak.tsx';
import { ACH_DETAILS } from '../../queries/constants.ts';
import { useGetACHDetails } from '../../queries/useGetACHDetails.ts';
import { usePostACHDetails } from '../../queries/usePostACHDetails.ts';
import messages from './PaymentSetup.messages.ts';
import { accountDetailSchema } from './PaymentSetupSchema.ts';
import { AppContext } from '../../AppContext.tsx';
import Modal from '@luna-protocol/core/src/components/Modal/Modal.tsx';
import { useGetACHAgreementUrl } from '../../queries/useGetACHAgreementUrl.ts';
import './PaymentSetup.scss';

interface AccountDetailProps {
  account_number: string | undefined;
  routing_number: string | undefined;
}

const PaymentSetup = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [enableFields, setEnableFields] = useState(false);
  const { loanApplicationID } = useContext(AppContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pending, setPending] = useState(false);

  const { postDetails } = usePostACHDetails();
  const { data: achData } = useGetACHDetails(loanApplicationID || '');
  const { data: ACHAgreementUrl } = useGetACHAgreementUrl();

  const initialValues: AccountDetailProps = {
    account_number: achData?.account_number || undefined,
    routing_number: achData?.routing_number || undefined,
  };

  useEffect(() => {
    if (achData?.account_number && achData?.routing_number) {
      setEnableFields(true);
    }
  }, [achData]);

  const onSubmit = (values: AccountDetailProps) => {
    setPending(true);
    const { account_number, routing_number } = values;

    const formattedValues = {
      account_number,
      routing_number,
    };

    postDetails(formattedValues, {
      onSuccess: () => {
        queryClient.invalidateQueries(ACH_DETAILS);
        navigate('/sign_docs');
      },
      onError: () => {
        setPending(false);
      },
    });
  };

  const handleSkip = () => {
    navigate('/sign_docs');
  };

  return (
    <>
      <BackButton path="/select_program" inverted />
      <Body>
        <h1>{formatMessage(messages.title)}</h1>
        <p className="subtitle">
          <FormattedMessage
            id={messages.subtitle.id}
            values={{
              bold: chunks => <b>{chunks}</b>,
            }}
          />
        </p>
        <p>{formatMessage(messages.descriptionOne)}</p>
        <p>{formatMessage(messages.descriptionTwo)}</p>
        <p>
          {formatMessage(messages.readMore, {
            url: (
              <a onClick={() => setModalIsOpen(true)} className={'legal-link'}>
                here
              </a>
            ),
          })}
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={accountDetailSchema}
          validateOnMount={true}
          validateOnChange
          enableReinitialize>
          {({ values, errors, handleBlur, handleSubmit, handleChange }) => (
            <Form>
              <fieldset className="fieldset fullWidth">
                <Checkbox
                  label={formatMessage(messages.autoPaymentsLabel)}
                  name="opt_in"
                  onChange={() => setEnableFields(!enableFields)}
                  value={enableFields}
                  error={undefined}
                  required={false}
                />
              </fieldset>
              <LineBreak bordered />
              <fieldset className="fieldset">
                <Input
                  label={formatMessage(messages.routingLabel)}
                  placeholder={formatMessage(messages.fieldPlaceholder)}
                  name="routing_number"
                  onChange={handleChange}
                  value={values.routing_number}
                  error={errors.routing_number}
                  onBlur={handleBlur}
                  type="text"
                  tooltip="Must be a 9-digit number."
                  disabled={!enableFields}
                />
                <Input
                  label={formatMessage(messages.accountNumberLabel)}
                  placeholder={formatMessage(messages.fieldPlaceholder)}
                  name="account_number"
                  onChange={handleChange}
                  value={values.account_number}
                  error={errors.account_number}
                  onBlur={handleBlur}
                  type="text"
                  tooltip="Must be between 10-12 digits"
                  disabled={!enableFields}
                />
              </fieldset>

              <LineBreak bordered />
              <ButtonGroup fullWidth>
                <Button type="submit" onClick={handleSkip} variant="primary">
                  {formatMessage(messages.skipButton)}
                </Button>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  pending={pending}
                  variant="secondary"
                  disabled={!(values.account_number && values.routing_number)}>
                  {formatMessage(messages.continueButton)}
                </Button>
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      </Body>
      {modalIsOpen && ACHAgreementUrl !== undefined && (
        <Modal onClose={() => setModalIsOpen(false)} title={formatMessage(messages.modalTitle)}>
          <embed type={'application/pdf'} src={ACHAgreementUrl?.url} width="100%" height="100%" />
        </Modal>
      )}
    </>
  );
};

export default PaymentSetup;
