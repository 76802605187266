import axios from 'axios';
import { useMutation } from 'react-query';

import getBaseApi from '@luna-protocol/core/src/utils/getBaseApi';

export interface StartPOIRequest {
  loan_application_id: string;
  proof_type: string;
}

export interface StartPOIResponse {
  link_token: string;
  user_token: string;
}

const useStartPOI = () => {
  const mutation = useMutation(async (req: StartPOIRequest) => {
    const resp = await axios.post(
      `${getBaseApi()}/pre_qualification/${req.loan_application_id}/poi`,
      {
        proof_type: req.proof_type,
      },
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('user_token')}` },
      },
    );

    if (resp.status !== 200) {
      throw new Error('unable to start proof of income');
    }

    return resp.data as StartPOIResponse;
  });

  return {
    postStartPOI: mutation.mutate,
    ...mutation,
  };
};

export { useStartPOI };
