import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'poi_document.title',
    defaultMessage: 'Proof of income',
  },
  detail1: {
    id: 'poi_document.detail1',
    defaultMessage: "We've partnered with Plaid to make your proof of income process as seamless as possible.",
  },
  detail2: {
    id: 'poi_document.detail2',
    defaultMessage: "Please click 'Continue' to verify your income using Plaid Document Income.",
  },
  disclaimer: {
    id: 'poi_document.disclaimer',
    defaultMessage:
      'Plaid Inc., based in San Francisco, CA, provides fast and easy to use solutions for identity verification and proof of income validation, among other services.',
  },
  disclaimerMore: {
    id: 'poi_document.disclaimerMore',
    defaultMessage: 'Click here to learn more.',
  },
  continue: {
    id: 'poi_document.continue',
    defaultMessage: 'Continue',
  },
  back: {
    id: 'poi_document.back',
    defaultMessage: 'Back',
  },
});

export default messages;
